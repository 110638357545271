import React, { SyntheticEvent } from 'react'
import { PlateDesignBannerContainer } from "@components/GenericBannerContainer"

import { Grid, DropdownProps, Icon, Visibility, Container, Loader } from "semantic-ui-react";
import { PlateDesignImagesContainer } from "@components/PlateDesignImagesContainer";
import { PlateDesignDetails } from "@components/PlateDesignDetailsContainer";

import {
    PlateColor,
    CurrentPlateDesign,
    PlateDesignModel,
    TextColor,
    Brand,
    PlateModel,
    VehicleType
} from "@models/ProductModels";
import { DesignPreviewProps } from './Types';
import { MessagePlateLocations } from '@utils/Constant';
import { PlateService } from '@services/PlateService';
import { isDefaultCombination } from '@utils/Helpers';
import { calculateDefaultPrice } from './designPreviewHelper';
import { BackLink, BackLinkContainer, PlateImageGridColumn } from '@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles';
import { Hyperlink } from '@elements/index';
import theme from '@styles/theme';
import { StickyTopMenu } from '@components/SelectStyle/SelectStyle.styles';
import { PlateDesignImagesBannerStrip } from '@components/PlateDesignImagesContainer/PlateDesignImagesContainer';
import { H4 } from '@styles/Global.styles';
import { BannerPricingAmount, InfoBannerInfoContainer, PriceLoadingMessage, PricingFrom } from '@components/PlateDesignDetailsContainer/PlateDesignDetails.styles';
import Axios from 'axios';
import { PlateDesignDetailWrapper, ViewMoreWrapper } from './PlateDesignsPreview.styles';
import e from 'express';

interface DesignPreviewStates {
    loading: boolean
    infoBannerVisible: boolean
    loadingPrice: boolean
    categoryName: string
    brand?: Brand
    expandDetailButtonVisible: boolean
    manualExpandFullDetails: boolean
    plateColor: PlateColor
    frontPlate: PlateDesignModel
    backPlate: PlateDesignModel
    vehicleType: VehicleType
    price: number | undefined
    fontMap: {
        CharacterMapSmall: { [key: string]: number },
        CharacterMapLarge: { [key: string]: number }
    } | undefined
}

class PlateDesignPreview extends React.Component<DesignPreviewProps, DesignPreviewStates>{

    CancelToken = Axios.CancelToken;
    source = this.CancelToken.source();
    constructor(props: DesignPreviewProps) {
        super(props);
        const defaultProps = props.currentPlateDesign === undefined ? props.productCategoryList.buildDefaultPlateDesignProps() : props.currentPlateDesign;
        this.state = {
            loading: true,
            infoBannerVisible: false,
            loadingPrice: !isDefaultCombination(props.formattedCombination, props.vehicleType),
            expandDetailButtonVisible: true,
            manualExpandFullDetails: false,
            categoryName: props.metaInfo.name,
            vehicleType: props.vehicleType,
            plateColor: defaultProps.plateColor,
            frontPlate: defaultProps.frontPlate,
            backPlate: defaultProps.backPlate,
            brand: defaultProps.brand,
            price: calculateDefaultPrice(props.metaInfo, props.transactionType),
            fontMap: undefined
        }
    }
    componentDidMount() {
        if (this.state.fontMap === undefined) {
            this.loadMessageFontMap();
        }
        this.updatePlatePrice();
        this.setState({ loading: false });
    }
    componentWillUnmount() {
        this.source.cancel("Operation canceled by the user.");
    }

    showInfoBanner = () => {
        this.setState({ infoBannerVisible: true })
    }
    hideInfoBanner = () => {
        this.setState({ infoBannerVisible: false })
    }
    updatePlatePrice = () => {
        this.calculatePlatePrice(this.state.frontPlate, this.state.backPlate);
    }

    calculatePlatePrice = (frontPlate: PlateDesignModel, backPlate: PlateDesignModel) => {
        if (!isDefaultCombination(this.props.formattedCombination, this.props.vehicleType)) {
            this.setState({ loadingPrice: true });
            PlateService.getPlatePrice(
                this.props.apiUrl,
                this.props.transactionType.id,
                frontPlate.plateDesignCode,
                frontPlate.plateSize.id,
                backPlate.plateDesignCode,
                backPlate.plateSize.id,
                this.props.vehicleType.id,
                this.props.formattedCombination,
                this.source.token).then((response) => {
                    const price = response.TotalPriceIncGST;
                    this.setState({ price: price, loadingPrice: false });
                }).catch(err => {
                    console.log(err);
                    this.setState({ loadingPrice: false });
                });
        }
    }

    loadMessageFontMap = () => {
        PlateService.getCaptionFontMap(this.props.apiUrl, this.source.token).then(data => {
            this.setState({ fontMap: data });
        }).catch(err => {
            console.log("Cancelled");
        });
    }
    componentDidUpdate() {
        if (this.state.categoryName !== this.props.metaInfo.name) {
            this.setState({
                categoryName: this.props.metaInfo.name,
                plateColor: this.props.currentPlateDesign.plateColor,
                frontPlate: this.props.currentPlateDesign.frontPlate,
                backPlate: this.props.currentPlateDesign.backPlate,
                brand: this.props.currentPlateDesign.brand,
                expandDetailButtonVisible: false,
                manualExpandFullDetails: true,
            });
            this.calculatePlatePrice(this.props.currentPlateDesign.frontPlate, this.props.currentPlateDesign.backPlate);
        }
    }

    handlePlateColorChange = (currentPlateDesign: CurrentPlateDesign, plateColorName: string) => {
        this.setState({ loading: true });
        const currentDesign = this.props.productCategoryList.buildPlateDesignModelByPlateColorAndBrand(currentPlateDesign, plateColorName, this.state.brand);

        currentDesign.frontPlate.topMessageColor = this.state.frontPlate.topMessageColor;
        currentDesign.frontPlate.topMessageText = this.state.frontPlate.topMessageText;
        currentDesign.frontPlate.bottomMessageColor = this.state.frontPlate.bottomMessageColor;
        currentDesign.frontPlate.bottomMessageText = this.state.frontPlate.bottomMessageText;
        currentDesign.frontPlate.captionPositon = this.state.frontPlate.captionPositon;

        currentDesign.backPlate.topMessageColor = this.state.backPlate.topMessageColor;
        currentDesign.backPlate.topMessageText = this.state.backPlate.topMessageText;
        currentDesign.backPlate.bottomMessageColor = this.state.backPlate.bottomMessageColor;
        currentDesign.backPlate.bottomMessageText = this.state.backPlate.bottomMessageText;
        currentDesign.backPlate.captionPositon = this.state.backPlate.captionPositon;

        this.calculatePlatePrice(currentDesign.frontPlate, currentDesign.backPlate);
        this.setState({
            loading: false,
            brand: currentDesign.brand,
            plateColor: currentDesign.plateColor,
            frontPlate: currentDesign.frontPlate,
            backPlate: currentDesign.backPlate
        });
    }

    //TODO: the plate design code should be same during text color change. use plate design code instead but be aware of front and back plate design code are different
    handlePlateTextColorChange = (currentPlateDesign: CurrentPlateDesign, textColor: TextColor) => {
        this.setState({ loading: true });
        const currentDesign = this.props.productCategoryList.buildPlateDesignPropsByPlateColorAndTextColor(this.state.plateColor, textColor.id, this.state.brand, undefined, currentPlateDesign.frontPlate, currentPlateDesign.backPlate,);
        this.setState({ loading: false, brand: currentDesign.brand, plateColor: currentDesign.plateColor, frontPlate: currentDesign.frontPlate, backPlate: currentDesign.backPlate });
    }
    handleFrontPlateSizeChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        this.setState({ loading: true });
        const plateSizeId = data.value as number;
        const newFrontPlateMoel = this.props.productCategoryList.buildPlateModelForPlateSizeChange(this.state.frontPlate, this.state.backPlate, plateSizeId);
        const newModel = this.mapPlateModelToPlateDesignModel(newFrontPlateMoel);

        this.calculatePlatePrice(newModel, this.state.backPlate);
        this.setState({ loading: false, frontPlate: newModel });
    }
    loadExistingTopMessage = () => {
        if (this.state.frontPlate.topMessageText !== undefined && this.state.frontPlate.topMessageText.trim().length > 0) {
            return this.state.frontPlate.topMessageText;
        }
        if (this.state.backPlate.topMessageText !== undefined && this.state.backPlate.topMessageText.trim().length > 0) {
            return this.state.backPlate.topMessageText;
        }
        return undefined;
    }
    loadExistingBottomMessage = () => {
        if (this.state.frontPlate.bottomMessageText !== undefined && this.state.frontPlate.bottomMessageText.trim().length > 0) {
            return this.state.frontPlate.bottomMessageText;
        }
        if (this.state.backPlate.bottomMessageText !== undefined && this.state.backPlate.bottomMessageText.trim().length > 0) {
            return this.state.backPlate.bottomMessageText;
        }
    }
    handleBackPlateSizeChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        this.setState({ loading: true });
        const plateSizeId = data.value as number;
        const newBackPlateMoel = this.props.productCategoryList.buildPlateModelForPlateSizeChange(this.state.frontPlate, this.state.backPlate, plateSizeId);
        const newDesignModel = this.mapPlateModelToPlateDesignModel(newBackPlateMoel);
        this.calculatePlatePrice(this.state.frontPlate, newDesignModel);
        this.setState({ loading: false, backPlate: newDesignModel });
    }
    mapPlateModelToPlateDesignModel = (plateModel: PlateModel): PlateDesignModel => {
        const captionPosition = plateModel.captionPositon;
        let topMessageColor = undefined;
        let bottomMessageColor = undefined;
        let topMessageText = undefined;
        let bottomMessageText = undefined;
        if (captionPosition !== undefined) {
            if (captionPosition.value === MessagePlateLocations.TopAndBottom || captionPosition.value === MessagePlateLocations.TopOnly) {
                topMessageColor = plateModel.messageColors === undefined ? undefined : plateModel.messageColors[0];
                topMessageText = this.loadExistingTopMessage();
            }
            if (captionPosition.value === MessagePlateLocations.TopAndBottom || captionPosition.value === MessagePlateLocations.BottomOnly) {
                bottomMessageColor = plateModel.messageColors === undefined ? undefined : plateModel.messageColors[0];
                bottomMessageText = this.loadExistingBottomMessage();
            }
        }
        const newBackPlateDesignModel: PlateDesignModel = {
            plateDesignCode: plateModel.plateDesignCode,
            plateSize: plateModel.plateSize,
            textColor: plateModel.textColor,
            captionPositon: plateModel.captionPositon,
            topMessageColor: topMessageColor,
            bottomMessageColor: bottomMessageColor,
            topMessageText: topMessageText,
            bottomMessageText: bottomMessageText
        }
        return newBackPlateDesignModel;
    }
    handleCaptionPositionChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const position = data.value as string //bottom_only, top_only, or top_and_bottom
        const currentDesign = this.props.productCategoryList.buildPlateDesignPropsByPlateColorAndTextColor(this.state.plateColor, this.state.frontPlate.textColor.id, undefined, position);
        const newFrontPlate = { ...currentDesign.frontPlate, topMessageText: this.state.frontPlate.topMessageText, bottomMessageText: this.state.frontPlate.bottomMessageText, topMessageColor: this.state.frontPlate.topMessageColor, bottomMessageColor: this.state.frontPlate.bottomMessageColor, captionMaxLength: this.state.frontPlate.captionMaxLength, bottomCaptionMaxLength: this.state.frontPlate.bottomCaptionMaxLength }
        const newBackPlate = { ...currentDesign.backPlate, topMessageText: this.state.backPlate.topMessageText, bottomMessageText: this.state.backPlate.bottomMessageText, topMessageColor: this.state.backPlate.topMessageColor, bottomMessageColor: this.state.backPlate.bottomMessageColor, captionMaxLength: this.state.frontPlate.captionMaxLength, bottomCaptionMaxLength: this.state.frontPlate.bottomCaptionMaxLength }

        this.setState({ loading: false, frontPlate: newFrontPlate, backPlate: newBackPlate });
    }
    handleBottomMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            (this.state.frontPlate.captionPositon.value === MessagePlateLocations.BottomOnly || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newFrontPlate = { ...this.state.frontPlate, bottomMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined &&
            (this.state.backPlate.captionPositon.value === MessagePlateLocations.BottomOnly || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newBackPlate = { ...this.state.backPlate, bottomMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleTopMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            (this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopOnly || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newFrontPlate = { ...this.state.frontPlate, topMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined &&
            (this.state.backPlate.captionPositon.value === MessagePlateLocations.TopOnly || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newBackPlate = { ...this.state.backPlate, topMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleLockedMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom) {
            const newFrontPlate = { ...this.state.frontPlate, topMessageColor: color, bottomMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined && this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom) {
            const newBackPlate = { ...this.state.backPlate, topMessageColor: color, bottomMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleTopMessageTextChange = (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => {
        let text = data.value.toUpperCase();
        text = text.replace(/[^a-zA-Z0-9\t\n .,/<>?;:"'`!@#$%^&*()[\]{}_+=|~-]*/g, "");
        if (this.state.frontPlate.captionPositon !== undefined && (
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopOnly)) {
            this.setState(prevState => ({
                ...prevState,
                frontPlate: {
                    ...prevState.frontPlate,
                    topMessageText: text
                }
            }))
        }
        if (this.state.backPlate.captionPositon !== undefined && (
            this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopOnly)) {
            this.setState(prevState => ({
                ...prevState,
                backPlate: {
                    ...prevState.backPlate,
                    topMessageText: text
                }
            }))
        }
    }
    handleBottomMessageTextChange = (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => {
        let text = data.value.toUpperCase();
        text = text.replace(/[^a-zA-Z0-9\t\n .,/<>?;:"'`!@#$%^&*()[\]{}_+=|~-]*/g, "");
        if (this.state.frontPlate.captionPositon !== undefined && (
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.frontPlate.captionPositon.value === MessagePlateLocations.BottomOnly)) {
            this.setState(prevState => ({
                ...prevState,
                frontPlate: {
                    ...prevState.frontPlate,
                    bottomMessageText: text
                }
            }))
        }
        if (this.state.backPlate.captionPositon !== undefined && (
            this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.backPlate.captionPositon.value === MessagePlateLocations.BottomOnly)) {
            this.setState(prevState => ({
                ...prevState,
                backPlate: {
                    ...prevState.backPlate,
                    bottomMessageText: text
                }
            }))
        }
    }
    handleBrandChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps, currentPlateDesign: CurrentPlateDesign) => {
        const currentDesign = this.props.productCategoryList.buildPlateDesignByBrand(currentPlateDesign, data.value as string)
        this.setState({ loading: false, brand: currentDesign.brand, plateColor: currentDesign.plateColor, frontPlate: currentDesign.frontPlate, backPlate: currentDesign.backPlate });

        this.calculatePlatePrice(currentDesign.frontPlate, currentDesign.backPlate);
    }

    render() {
        const { apiUrl, metaInfo, formattedCombination, vehicleType, transactionType, productCategoryList, title, showBackToAllDesign } = this.props;
        const { loading, plateColor, brand, frontPlate, backPlate, fontMap, price, loadingPrice } = this.state;
        const isDefaultComb = isDefaultCombination(formattedCombination, vehicleType);
        const currentPlateDesign: CurrentPlateDesign = {
            brand: brand,
            plateColor: plateColor,
            frontPlate: frontPlate,
            backPlate: backPlate
        };
        const plateImageModel = {
            formattedCombination: formattedCombination,
            vehicleType: vehicleType,
            frontPlate: frontPlate,
            backPlate: backPlate
        };
        const plateImageModelWithTitle = {
            frontPlate: frontPlate,
            backPlate: backPlate,
            formattedCombination: formattedCombination,
            vehicleType: vehicleType,
            title: title
        }
        const pageLoading = loading || this.props.metaInfo.name !== this.state.categoryName;
        return (
            <div>

                <PlateDesignBannerContainer bgLeftColor={theme.brand.colors.grey} paddingTop={40} paddingBottom={40} mobilePaddingTop={0} mobilePaddingBottom={0}>
                    {
                        pageLoading === false &&
                        <StickyTopMenu borderless fixed='top' visible={this.state.infoBannerVisible}>
                            <Container>
                                <Grid>
                                    <Grid.Column width={8}>
                                        <PlateDesignImagesBannerStrip
                                            apiUrl={apiUrl}
                                            plateImageModel={plateImageModel} />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <InfoBannerInfoContainer>
                                            <H4 textAlign="left">
                                                {this.props.metaInfo.name}
                                            </H4>

                                            {
                                                isDefaultComb &&
                                                <PricingFrom>From</PricingFrom>
                                            }
                                            {
                                                loadingPrice ?
                                                    <PriceLoadingMessage>Updating price ...</PriceLoadingMessage> :
                                                    <BannerPricingAmount>${price}</BannerPricingAmount>
                                            }
                                        </InfoBannerInfoContainer>
                                    </Grid.Column>
                                </Grid>
                            </Container>
                        </StickyTopMenu>
                    }

                    {pageLoading === false ?
                        <Grid columns={2}>
                            <PlateImageGridColumn mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                                {
                                    showBackToAllDesign && <BackLinkContainer>
                                        <Hyperlink url="/plate-designs/">
                                            <BackLink>
                                                <Icon name='chevron left' size='large' /> BACK TO ALL DESIGNS
                                            </BackLink>
                                        </Hyperlink>
                                    </BackLinkContainer>
                                }
                                <PlateDesignImagesContainer
                                    apiUrl={apiUrl}
                                    plateImageModel={plateImageModelWithTitle}
                                />
                            </PlateImageGridColumn>
                            <Grid.Column mobile={16} tablet={16} computer={8} largeScreen={8} widescreen={8}>
                                <Visibility onTopPassed={this.showInfoBanner} fireOnMount={true} onTopPassedReverse={this.hideInfoBanner} once={false}>
                                    {
                                        fontMap ?
                                            <PlateDesignDetailWrapper setMaxHeight={this.state.expandDetailButtonVisible}>
                                                <PlateDesignDetails
                                                    apiUrl={apiUrl}
                                                    isGift={this.props.isGift}
                                                    productCategoryList={productCategoryList}
                                                    formattedCombination={formattedCombination}
                                                    price={price}
                                                    loadingPrice={loadingPrice}
                                                    metaInfo={metaInfo}
                                                    vehicleType={vehicleType}
                                                    transactionType={transactionType}
                                                    fontMap={fontMap}
                                                    currentPlateDesign={currentPlateDesign}
                                                    handleBrandChange={this.handleBrandChange}
                                                    handlePlateColorChange={this.handlePlateColorChange}
                                                    handlePlateTextColorChange={this.handlePlateTextColorChange}
                                                    handleFrontPlateSizeChange={this.handleFrontPlateSizeChange}
                                                    handleBackPlateSizeChange={this.handleBackPlateSizeChange}
                                                    handleCaptionPositionChange={this.handleCaptionPositionChange}
                                                    handleTopMessageColorChange={this.handleTopMessageColorChange}
                                                    handleBottomMessagecolorChange={this.handleBottomMessageColorChange}
                                                    handleLockedMessageColorChange={this.handleLockedMessageColorChange}
                                                    handleTopMessageTextChange={this.handleTopMessageTextChange}
                                                    handleBottomMessageTextChange={this.handleBottomMessageTextChange} />
                                            </PlateDesignDetailWrapper>
                                            :
                                            <Loader active />
                                    }
                                </Visibility>
                            </Grid.Column>
                        </Grid>
                        :
                        <Loader active inline='centered' content="Loading..." />
                    }
                </PlateDesignBannerContainer>
            </div>);

    }
}

export default PlateDesignPreview;