import styled from "@emotion/styled";
import React from "react";
import { Menu, Segment } from "semantic-ui-react";
import { H4 } from "@styles/Global.styles";
import theme from "@styles/theme";

export const PlateDesignDetailWrapper = styled(({setMaxHeight,...others})=><div {...others} />)`
    overflow:hidden;
`
export const ViewMoreWrapper = styled.div`
    position: relative;
    top: -100px;
    background-image: linear-gradient(to bottom, rgba(255,255,255,0.5), rgba(255,255,255,1));
    height: 100px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    padding-top: 60px;
    margin-bottom: -100px;
    cursor: pointer;
`
export const StickyTopMenu = styled(({visible, ...others})=><Menu {...others} />)`
    display: ${props=>props.visible  === true ? "block !important" : "none !important"};
    padding: 10px 0 10px 0;
    background-color: black !important;
`
export const MobileHeader = styled(H4)`
    display: inline-block;
    padding-right: 20px;
    color:white;
`
export const MobileBannerPricingAmount = styled.span`
    vertical-align: top;
    text-align: left;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    color: #3DAE2B;
`
export const MobilePriceLoadingMessage = styled.span`
    height: 28px;
    font-size: 16px;
    line-height: 28px;
    color:white;
`
export const BasicSegment = styled(Segment)`
    padding: 0 !important;
`
export const MobilePlateDesignSliderContainer = styled.div`
    width: 90%;
    margin: auto;
`
export const NextArrowIcon = styled.div`
    &:before{
        color: ${theme.brand.colors.green};
    }
`
export const PrevArrowIcon = styled.div`
    &:before{
        color: ${theme.brand.colors.green};
    }
`