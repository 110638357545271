import React, { SyntheticEvent } from 'react'
import { ProductModelCategoryList, CurrentPlateDesign, TextColor, PlateCategoryMetaInfo, VehicleType, Brand, PlateColor, PlateDesignModel, TransactionType, PlateModel } from '@models/ProductModels';
import { PlateDesignDetails } from '@components/PlateDesignDetailsContainer';
import { DropdownProps, Visibility, Container, Loader } from 'semantic-ui-react';
import { MessagePlateLocations } from '@utils/Constant';
import { PlateService } from '@services/PlateService';
import { calculateDefaultPrice } from './designPreviewHelper';
import { isDefaultCombination } from '@utils/Helpers';
import { MobileStyleHeading, MobilePreviewContainer, MobileStyleHeadingAddToCart } from '@components/PlateDesignImagesContainer/PlateDesignImagesContainer.styles';
import Axios from 'axios';
import { MobilePlateDesignImagesContainer, PlateDesignImagesBannerStrip } from '@components/PlateDesignImagesContainer/PlateDesignImagesContainer';
import { StickyTopMenu, MobileHeader, MobileBannerPricingAmount, MobilePriceLoadingMessage, BasicSegment } from './PlateDesignsPreview.styles';
import { PricingFrom } from '@components/PlateDesignDetailsContainer/PlateDesignDetails.styles';
import MobileDesignSlider from './MobileDesignSlider';
import darkTheme from '@styles/dark-theme';
interface Props {
    apiUrl: string
    formattedCombination: string
    selectedPlateDesign: {
        products: ProductModelCategoryList,
        metaInfo: PlateCategoryMetaInfo
    }
    currentPlateDesign: CurrentPlateDesign
    vehicleType: VehicleType
    transactionType: TransactionType
    title: string
    onSelectCard: (data: string) => void
    isGift?: boolean
    darkTheme: boolean
}

interface States {
    loading: boolean
    brand?: Brand
    infoBannerVisible: boolean
    loadingPrice: boolean
    plateColor: PlateColor
    frontPlate: PlateDesignModel
    backPlate: PlateDesignModel
    vehicleType: VehicleType
    categoryName: string
    price: number
    fontMap: {
        CharacterMapSmall: { [key: string]: number },
        CharacterMapLarge: { [key: string]: number }
    } | undefined
}

class MobilePlateDesignPreview extends React.Component<Props, States>{

    CancelToken = Axios.CancelToken;
    source = this.CancelToken.source();
    static defaultProps: { darkTheme: string; };


    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            infoBannerVisible: false,
            categoryName: props.selectedPlateDesign.metaInfo.name,
            vehicleType: props.vehicleType,
            loadingPrice: !isDefaultCombination(props.formattedCombination, props.vehicleType),
            plateColor: props.currentPlateDesign.plateColor,
            frontPlate: props.currentPlateDesign.frontPlate,
            backPlate: props.currentPlateDesign.backPlate,
            brand: props.currentPlateDesign.brand,
            fontMap: undefined,
            price: calculateDefaultPrice(props.selectedPlateDesign.metaInfo, props.transactionType),
        }
    }

    loadMessageFontMap = () => {
        PlateService.getCaptionFontMap(this.props.apiUrl, this.source.token).then(data => {
            this.setState({ fontMap: data });
        })
    }

    componentDidMount() {
        if (this.state.fontMap === undefined) {
            this.loadMessageFontMap();
        }
        this.updatePlatePrice();
        this.setState({ loading: false });
    }
    
    updatePlatePrice = () => {
        this.calculatePlatePrice(this.state.frontPlate, this.state.backPlate);
    }

    showInfoBanner = () => {
        this.setState({ infoBannerVisible: true })
    }
    hideInfoBanner = () => {
        this.setState({ infoBannerVisible: false })
    }
    calculatePlatePrice = (frontPlate: PlateDesignModel, backPlate: PlateDesignModel) => {
        if (!isDefaultCombination(this.props.formattedCombination, this.props.vehicleType)) {
            this.setState({ loadingPrice: true });
            PlateService.getPlatePrice(
                this.props.apiUrl,
                this.props.transactionType.id,
                frontPlate.plateDesignCode,
                frontPlate.plateSize.id,
                backPlate.plateDesignCode,
                backPlate.plateSize.id,
                this.props.vehicleType.id,
                this.props.formattedCombination,
                this.source.token).then((response) => {
                    const price = response.TotalPriceIncGST;
                    this.setState({ price: price, loadingPrice: false });
                }).catch(err => {
                    this.setState({ loadingPrice: false });
                    console.log(err);
                });
        }
    }
    componentDidUpdate() {
        if (this.state.categoryName !== this.props.selectedPlateDesign.metaInfo.name) {
            this.setState({
                categoryName: this.props.selectedPlateDesign.metaInfo.name,
                vehicleType: this.props.vehicleType,
                plateColor: this.props.currentPlateDesign.plateColor,
                frontPlate: this.props.currentPlateDesign.frontPlate,
                backPlate: this.props.currentPlateDesign.backPlate,
                brand: this.props.currentPlateDesign.brand
            });
            this.calculatePlatePrice(this.props.currentPlateDesign.frontPlate, this.props.currentPlateDesign.backPlate);
        }
    }
    getSelectedPlateDesignCategory = (index: number) => {
        const selectedDesign = this.props.selectedPlateDesign
        this.props.onSelectCard(selectedDesign.metaInfo.name);
    }


    handlePlateColorChange = (currentPlateDesign: CurrentPlateDesign, plateColorName: string) => {
        this.setState({ loading: true });
        const currentDesign = this.props.selectedPlateDesign.products.buildPlateDesignModelByPlateColorAndBrand(currentPlateDesign, plateColorName, this.state.brand);
        this.calculatePlatePrice(currentDesign.frontPlate, currentDesign.backPlate);
        this.setState({ loading: false, brand: currentDesign.brand, plateColor: currentDesign.plateColor, frontPlate: currentDesign.frontPlate, backPlate: currentDesign.backPlate });
    }

    //TODO: the plate design code should be same during text color change. use plate design code instead but be aware of front and back plate design code are different
    handlePlateTextColorChange = (currentPlateDesign: CurrentPlateDesign, textColor: TextColor) => {
        this.setState({ loading: true });
        const currentDesign = this.props.selectedPlateDesign.products.buildPlateDesignPropsByPlateColorAndTextColor(this.state.plateColor, textColor.id, this.state.brand, undefined, currentPlateDesign.frontPlate, currentPlateDesign.backPlate);
        this.setState({ loading: false, brand: currentDesign.brand, plateColor: currentDesign.plateColor, frontPlate: currentDesign.frontPlate, backPlate: currentDesign.backPlate });
    }
    handleFrontPlateSizeChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        this.setState({ loading: true });
        const plateSizeId = data.value as number;
        const newFrontPlateMoel = this.props.selectedPlateDesign.products.buildPlateModelForPlateSizeChange(this.state.frontPlate, this.state.backPlate, plateSizeId);
        const newPlateDesignModel = this.mapPlateModelToPlateDesignModel(newFrontPlateMoel)
        this.calculatePlatePrice(newPlateDesignModel, this.state.backPlate);
        this.setState({ loading: false, frontPlate: newPlateDesignModel });
    }
    handleBackPlateSizeChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        this.setState({ loading: true });
        const plateSizeId = data.value as number;
        const newBackPlateMoel = this.props.selectedPlateDesign.products.buildPlateModelForPlateSizeChange(this.state.frontPlate, this.state.backPlate, plateSizeId);
        const newPlateDesignModel = this.mapPlateModelToPlateDesignModel(newBackPlateMoel)
        this.calculatePlatePrice(this.state.frontPlate, newPlateDesignModel);
        this.setState({ loading: false, backPlate: newPlateDesignModel });
    }

    mapPlateModelToPlateDesignModel = (plateModel: PlateModel): PlateDesignModel => {
        const captionPosition = plateModel.captionPositon;
        let topMessageColor = undefined;
        let bottomMessageColor = undefined;
        let topMessageText = undefined;
        let bottomMessageText = undefined;
        if (captionPosition !== undefined) {
            if (captionPosition.value === MessagePlateLocations.TopAndBottom || captionPosition.value === MessagePlateLocations.TopOnly) {
                topMessageColor = plateModel.messageColors === undefined ? undefined : plateModel.messageColors[0];
                topMessageText = this.loadExistingTopMessage();
            }
            if (captionPosition.value === MessagePlateLocations.TopAndBottom || captionPosition.value === MessagePlateLocations.BottomOnly) {
                bottomMessageColor = plateModel.messageColors === undefined ? undefined : plateModel.messageColors[0];
                bottomMessageText = this.loadExistingBottomMessage();
            }
        }

        const newBackPlateDesignModel: PlateDesignModel = {
            plateDesignCode: plateModel.plateDesignCode,
            plateSize: plateModel.plateSize,
            textColor: plateModel.textColor,
            captionPositon: plateModel.captionPositon,
            topMessageColor: topMessageColor,
            bottomMessageColor: bottomMessageColor,
            topMessageText: topMessageText,
            bottomMessageText: bottomMessageText
        }
        return newBackPlateDesignModel;
    }
    loadExistingTopMessage = () => {
        if (this.state.frontPlate.topMessageText !== undefined && this.state.frontPlate.topMessageText.trim().length > 0) {
            return this.state.frontPlate.topMessageText;
        }
        if (this.state.backPlate.topMessageText !== undefined && this.state.backPlate.topMessageText.trim().length > 0) {
            return this.state.backPlate.topMessageText;
        }
        return undefined;
    }

    loadExistingBottomMessage = () => {
        if (this.state.frontPlate.bottomMessageText !== undefined && this.state.frontPlate.bottomMessageText.trim().length > 0) {
            return this.state.frontPlate.bottomMessageText;
        }
        if (this.state.backPlate.bottomMessageText !== undefined && this.state.backPlate.bottomMessageText.trim().length > 0) {
            return this.state.backPlate.bottomMessageText;
        }
    }
    handleCaptionPositionChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => {
        const position = data.value as string //bottom_only, top_only, or top_and_bottom
        const currentDesign = this.props.selectedPlateDesign.products.buildPlateDesignPropsByPlateColorAndTextColor(this.state.plateColor, this.state.frontPlate.textColor.id, undefined, position);
        const newFrontPlate = { ...currentDesign.frontPlate, topMessageText: this.state.frontPlate.topMessageText, bottomMessageText: this.state.frontPlate.bottomMessageText, topMessageColor: this.state.frontPlate.topMessageColor, bottomMessageColor: this.state.frontPlate.bottomMessageColor }
        const newBackPlate = { ...currentDesign.backPlate, topMessageText: this.state.backPlate.topMessageText, bottomMessageText: this.state.backPlate.bottomMessageText, topMessageColor: this.state.backPlate.topMessageColor, bottomMessageColor: this.state.backPlate.bottomMessageColor }

        this.setState({ loading: false, frontPlate: newFrontPlate, backPlate: newBackPlate });
    }
    handleBottomMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            (this.state.frontPlate.captionPositon.value === MessagePlateLocations.BottomOnly || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newFrontPlate = { ...this.state.frontPlate, bottomMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined &&
            (this.state.backPlate.captionPositon.value === MessagePlateLocations.BottomOnly || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newBackPlate = { ...this.state.backPlate, bottomMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleTopMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            (this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopOnly || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newFrontPlate = { ...this.state.frontPlate, topMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined &&
            (this.state.backPlate.captionPositon.value === MessagePlateLocations.TopOnly || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom)) {
            const newBackPlate = { ...this.state.backPlate, topMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleLockedMessageColorChange = (color: TextColor) => {
        if (this.state.frontPlate.captionPositon !== undefined &&
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom) {
            const newFrontPlate = { ...this.state.frontPlate, topMessageColor: color, bottomMessageColor: color };
            this.setState({ frontPlate: newFrontPlate });
        }
        if (this.state.backPlate.captionPositon !== undefined && this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom) {
            const newBackPlate = { ...this.state.backPlate, topMessageColor: color, bottomMessageColor: color };
            this.setState({ backPlate: newBackPlate });
        }
    }
    handleTopMessageTextChange = (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => {
        let text = data.value.toUpperCase();
        text = text.replace(/[^a-zA-Z0-9\t\n .,/<>?;:"'`!@#$%^&*()[\]{}_+=|~-]*/g, "");
        if (this.state.frontPlate.captionPositon !== undefined && (
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopOnly)) {
            this.setState(prevState => ({
                ...prevState,
                frontPlate: {
                    ...prevState.frontPlate,
                    topMessageText: text
                }
            }))
        }
        if (this.state.backPlate.captionPositon !== undefined && (
            this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.backPlate.captionPositon.value === MessagePlateLocations.TopOnly)) {
            this.setState(prevState => ({
                ...prevState,
                backPlate: {
                    ...prevState.backPlate,
                    topMessageText: text
                }
            }))
        }
    }
    handleBottomMessageTextChange = (event: SyntheticEvent<HTMLElement, Event>, data: { value: string }) => {
        let text = data.value.toUpperCase();
        text = text.replace(/[^a-zA-Z0-9\t\n .,/<>?;:"'`!@#$%^&*()[\]{}_+=|~-]*/g, "");
        if (this.state.frontPlate.captionPositon !== undefined && (
            this.state.frontPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.frontPlate.captionPositon.value === MessagePlateLocations.BottomOnly)) {
            this.setState(prevState => ({
                ...prevState,
                frontPlate: {
                    ...prevState.frontPlate,
                    bottomMessageText: text
                }
            }))
        }
        if (this.state.backPlate.captionPositon !== undefined && (
            this.state.backPlate.captionPositon.value === MessagePlateLocations.TopAndBottom || this.state.backPlate.captionPositon.value === MessagePlateLocations.BottomOnly)) {
            this.setState(prevState => ({
                ...prevState,
                backPlate: {
                    ...prevState.backPlate,
                    bottomMessageText: text
                }
            }))
        }
    }
    handleBrandChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps, currentPlateDesign: CurrentPlateDesign) => {
        const currentDesign = this.props.selectedPlateDesign.products.buildPlateDesignByBrand(currentPlateDesign, data.value as string)
        this.calculatePlatePrice(currentDesign.frontPlate, currentDesign.backPlate);
        this.setState({ loading: false, brand: currentDesign.brand, plateColor: currentDesign.plateColor, frontPlate: currentDesign.frontPlate, backPlate: currentDesign.backPlate });
    }


    render() {
        const { apiUrl, formattedCombination, selectedPlateDesign, vehicleType, transactionType, title } = this.props;
        const isDefaultComb = isDefaultCombination(formattedCombination, vehicleType);
        const currentPlateDesign: CurrentPlateDesign = {
            brand: this.state.brand,
            plateColor: this.state.plateColor,
            frontPlate: this.state.frontPlate,
            backPlate: this.state.backPlate
        };
        const plateImageModel = {
            formattedCombination: formattedCombination,
            vehicleType: vehicleType,
            frontPlate: this.state.frontPlate,
            backPlate: this.state.backPlate
        }
        const pageLoading = this.props.selectedPlateDesign.metaInfo.name !== this.state.categoryName;
        return (
            <MobilePreviewContainer>
                {
                    pageLoading === false &&
                    <StickyTopMenu borderless fixed='top' visible={this.state.infoBannerVisible}>
                        <BasicSegment basic>
                            <Container>
                                <div>
                                    <MobileHeader textAlign="left">
                                        {this.state.categoryName}
                                    </MobileHeader>
                                    {
                                        isDefaultComb &&
                                        <PricingFrom>From</PricingFrom>
                                    }
                                    {
                                        this.state.loadingPrice ?
                                            <MobilePriceLoadingMessage>Updating price ...</MobilePriceLoadingMessage> :
                                            <MobileBannerPricingAmount>${this.state.price}</MobileBannerPricingAmount>
                                    }
                                </div>
                                <div>
                                    <PlateDesignImagesBannerStrip
                                        apiUrl={apiUrl}
                                        plateImageModel={plateImageModel} />
                                </div>
                            </Container>
                        </BasicSegment>

                    </StickyTopMenu>
                }
                <MobileStyleHeadingAddToCart>{title}</MobileStyleHeadingAddToCart>    
                {!pageLoading &&
                <MobilePlateDesignImagesContainer
                    apiUrl={apiUrl}
                    vehicleType={vehicleType}
                    currentPlateDesign={currentPlateDesign}
                    formattedCombination={formattedCombination}
                    darkTheme={darkTheme}
                    />       }  
                {
                    !pageLoading ?
                        <Visibility onTopPassed={this.showInfoBanner} offset={40} onTopPassedReverse={this.hideInfoBanner} once={false}>

                            <PlateDesignDetails
                                apiUrl={apiUrl}
                                isGift={this.props.isGift}
                                productCategoryList={selectedPlateDesign.products}
                                formattedCombination={formattedCombination}
                                metaInfo={selectedPlateDesign.metaInfo}
                                currentPlateDesign={currentPlateDesign}
                                vehicleType={vehicleType}
                                transactionType={transactionType}
                                fontMap={this.state.fontMap}
                                price={this.state.price}
                                loadingPrice={this.state.loadingPrice}
                                handleBrandChange={this.handleBrandChange}
                                handlePlateColorChange={this.handlePlateColorChange}
                                handleLockedMessageColorChange={this.handleLockedMessageColorChange}
                                handlePlateTextColorChange={this.handlePlateTextColorChange}
                                handleFrontPlateSizeChange={this.handleFrontPlateSizeChange}
                                handleBackPlateSizeChange={this.handleBackPlateSizeChange}
                                handleCaptionPositionChange={this.handleCaptionPositionChange}
                                handleTopMessageColorChange={this.handleTopMessageColorChange}
                                handleBottomMessagecolorChange={this.handleBottomMessageColorChange}
                                handleTopMessageTextChange={this.handleTopMessageTextChange}
                                handleBottomMessageTextChange={this.handleBottomMessageTextChange} 
                                darkTheme={true}/>

                        </Visibility>
                        :
                        <Loader active inline='centered' content="Loading..." />
                }

            </MobilePreviewContainer>
        )
    }
}

export default MobilePlateDesignPreview;

MobilePlateDesignPreview.defaultProps = {
    darkTheme:'false'
};