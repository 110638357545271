import { Modal } from "semantic-ui-react";
import React, { Fragment } from "react";
import { ModalContainer, H2 } from "@styles/Global.styles";
import { MobilePlateDesignPreview } from "@components/AddToCartPlateDesignsPreview";
import { PlateCategoryMetaInfo, ProductModelCategoryList, CurrentPlateDesign,VehicleType,TransactionType  } from "@models/ProductModels";
import { SlidingIcon } from "@assets/svgs";

interface Props {
    apiUrl: string
    formattedCombination: string
    
    selectedPlateDesign:{
      metaInfo: PlateCategoryMetaInfo
      products: ProductModelCategoryList
    }
    vehicleType: VehicleType
    title: string
    transactionType: TransactionType
    currentPlateDesign: CurrentPlateDesign | undefined
    handleSelectCard: (data:string) => void
    isGift?: boolean,
    darkTheme: boolean
}

interface States{
  modalOpen: boolean
}

class MobileSelectStyle extends React.Component<Props,States>{  
    
    state = {
        modalOpen: true
    }
    handleMobileModalClose = () =>{
        this.setState({ modalOpen: false });
    }
    render(){
        const {apiUrl,formattedCombination,vehicleType, transactionType, selectedPlateDesign,currentPlateDesign,handleSelectCard,title,isGift, darkTheme} = this.props;
        return(
          <Fragment>            
                {/* Mobile Version */}
                <MobilePlateDesignPreview   
                    apiUrl={apiUrl}
                    formattedCombination={formattedCombination}
                    title={title}
                    vehicleType={vehicleType}
                    transactionType={transactionType}
                    onSelectCard = {handleSelectCard}
                    currentPlateDesign={currentPlateDesign!}
                    selectedPlateDesign = {selectedPlateDesign}
                    isGift={isGift}
                    darkTheme={darkTheme}
                />            
          </Fragment>

        )
    }
}

export default MobileSelectStyle;