import React from "react"
import { Container, Icon } from "semantic-ui-react"
import {
  Back,
  Info,
  NavHeaderSegmentStyle
} from "./NavHeader.style"
import { NavHeaderType } from "./types"
import { navigate } from "gatsby"

export const NavHeader = (props: NavHeaderType) => {
  const { info, link, infoTextColor } = props

  const goBack = () => {
    if(link){
      if(typeof window !== 'undefined'){
        window.location.href=link;
      }
    } else{
      history.back()
    }
  }


  return (
    <NavHeaderSegmentStyle>
      <Back onClick={() => goBack() }>
        <Icon name='angle left' size='large'/>
        <span>Back</span>
      </Back>
      <Info color={infoTextColor}>
        {info}
      </Info>
    </NavHeaderSegmentStyle>
  )
}

NavHeader.defaultProps = {
  infoTextColor:'black'
};